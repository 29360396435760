import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';

import type IconProps from './iconTypes';

const ChatNew = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color = 'currentColor', strokeWidth = 1, ...otherProps } = props;

  const { palette } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path
        d="M20 5H4v11.14h7.83L16.86 20l.05-3.86H20V5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={strokeWidth}
      />
      <path fill={palette.primary.main} d="M24 4a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z" />
    </svg>
  );
});

ChatNew.displayName = 'ChatNew';

export default ChatNew;
