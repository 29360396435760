import { forwardRef } from 'react';

import type { SVGProps } from 'react';

type Props = SVGProps<SVGSVGElement> & {
  color?: string

};

const BidirectionalArrow = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const {
    color = 'currentColor',
    ...otherProps
  } = props;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <path d="M15.6312 8.22268L20 12.244L15.6312 16.2745" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.29741 16.2745L4 12.2532L8.29741 8.22266" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12.2426H4" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

BidirectionalArrow.displayName = 'BidirectionalArrow';

export default BidirectionalArrow;
